import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
const version = require('./../../../package.json').version;

class Privacy extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="officialInfo">
          <Typography className="cardTitle" variant="h4" fontFamily="SourceSansPro">
            {t('Privacy statement')}
          </Typography>
          <Typography className="cardContents" variant="body2" component="div" fontFamily="SourceSansPro">
            <h1>1. Datenschutz auf einen Blick</h1>
            <h2>ALLGEMEINE HINWEISE</h2>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
            identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgeführten Datenschutzerklärung.
            <br />
            <br />
            <h2>WER IST VERANTWORTLICH FÜR DIE DATENERFASSUNG AUF DIESER WEBSITE?</h2>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
            dem Impressum dieser Website entnehmen.
            <br />
            <br />
            <h2>WIE ERFASSEN WIR IHRE DATEN?</h2>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
            Daten handeln, die Sie bei der Registrierung eingeben.
            <br />
            Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
            technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
            Daten erfolgt automatisch, sobald Sie unsere Website betreten.
            <br />
            <br />
            <h2>WOFÜR NUTZEN WIR IHRE DATEN?</h2>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
            <br />
            <br />
            <h2>WELCHE RECHTE HABEN SIE BEZÜGLICH IHRER DATEN?</h2>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
            personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
            dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
            unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
            der zuständigen Aufsichtsbehörde zu.
            <br />
            <br />
            <h2>ANALYSE-TOOLS UND TOOLS VON DRITTANBIETERN</h2>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
            mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel
            anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse
            widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu
            finden Sie in der folgenden Datenschutzerklärung.
            <br />
            <br />
            <h1>2. Allgemeine Hinweise und Pflichtinformationen</h1>
            <h2>DATENSCHUTZ</h2>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerklärung.
            <br />
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
            sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
            das geschieht.
            <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
            <br />
            <br />
            <h2>HINWEIS ZUR VERANTWORTLICHEN STELLE</h2>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
            <br />
            <br />
            Awesome Technologies Innovationslabor GmbH
            <br /> Leightonstr. 2 97074 Würzburg
            <br />
            E-Mail: kontakt@awesome-technologies.de
            <br />
            <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.
            <br />
            <br />
            <h2>SSL- BZW. TLS-VERSCHLÜSSELUNG</h2>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
            von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            <br />
            <br />
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
            von Dritten mitgelesen werden.
            <br />
            <br />
            <h2>WIDERRUF IHRER EINWILLIGUNG ZUR DATENVERARBEITUNG</h2>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            <br />
            <br />
            <h2>RECHT AUF DATENÜBERTRAGBARKEIT</h2>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            <br />
            <br />
            <h2>RECHT AUF AUSKUNFT</h2>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
            über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
            Datenverarbeitung.
            <br />
            <br />
            <h2>RECHT AUF BERICHTIGUNG, EINSCHRÄNKUNG, LÖSCHUNG</h2>
            Weiterhin haben Sie ein Recht auf Berichtigung fehlerhafter oder unzutreffender Daten, Einschränkung der
            Verarbeitung Ihrer Daten oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
            personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            <br />
            <br />
            <h2>BESCHWERDERECHT BEI DER ZUSTÄNDIGEN AUFSICHTSBEHÖRDE</h2>
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
            Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der bayrische
            Landesdatenschutzbeauftragte. Ihn können Sie wie folgt kontaktieren:
            <br />
            <br />
            Postfach 22 12 19, 80502 München
            <br />
            E-Mail: poststelle@datenschutz-bayern.de
            <br />
            Homepage: https://www.datenschutz-bayern.de
            <br />
            <br />
            <h2>WIDERSPRUCH GEGEN WERBE-MAILS</h2>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
            Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
            Werbeinformationen, etwa durch Spam-E-Mails, vor.
            <br />
            <br />
            <h1>3. Datenerfassung auf unserer Website</h1>
            <h2>COOKIES</h2>
            Diese Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
            Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
            sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr
            Browser speichert.
            <br />
            Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres
            Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen
            oder diese nach spätestens 12 Monaten abgelaufen sind. Diese Cookies ermöglichen es uns, Ihren Browser beim
            nächsten Besuch wiederzuerkennen.
            <br />
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies
            nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
            automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
            <br />
            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter,
            von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
            Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies
            (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser
            Datenschutzerklärung gesondert behandelt.
            <br />
            <br />
            <h2>SERVER-LOG-DATEIEN</h2>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
            die Ihr Browser automatisch an uns übermittelt. Dies sind:
            <br />
            Browsertyp und Browserversion
            <br />
            verwendetes Betriebssystem
            <br />
            Referrer URL
            <br />
            Hostname des zugreifenden Rechners
            <br />
            Uhrzeit der Serveranfrage
            <br />
            IP-Adresse
            <br />
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die in den
            Server-Log-Dateien erfassten Informationen werden zu Sicherheits- und Supportzwecken maximal 7 Tage
            gespeichert und anschließend automatisch gelöscht.
            <br />
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
            Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            <br />
            <br />
            <h2>REGISTRIERUNG AUF DIESER WEBSITE</h2>
            Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die
            dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für
            den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig
            angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
            <br />
            Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die
            bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
            <br />
            Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht
            eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung
            bleibt vom Widerruf unberührt.
            <br />
            Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf unserer Website
            registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
            <br />
            <br />
            <h1>4. Name und Anschrift des Datenschutzbeauftragten</h1>
            Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:
            <br />
            <br />
            Beatriz Loos SiDIT GmbH
            <br />
            Tel.: +49 (0)931-780877-0
            <br />
            E-Mail: datenschutz@awesome-technologies.de
            <br />
            <br />
            Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren
            Datenschutzbeauftragten wenden.
            <br />
            <br />
            Stand: September 2023
          </Typography>
        </div>
        <div className="versionInfo">version: {version}</div>
      </div>
    );
  }
}

export default withTranslation()(Privacy);
