import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import { getVectorConfig } from '../utils/getConfig';

class ImagingStudy extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      studyIds: [],
      toggler: false,
      selectedId: '',
      slide: 1,
      config: '',
    };
  }

  async loadData() {
    const client = this.context.client;
    const config = await getVectorConfig('/config');
    this.setState({ config: config });

    this.props.ids.map(async id => {
      await client
        .request(
          {
            url: `${id}`,
            federatedServerUrl: this.props.externalServerUrl,
          },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(data => {
          let study_id = data.identifier[0].value.split(':').slice(-1);
          this.setState({
            studyIds: [...this.state.studyIds, study_id],
          });
        });
    });
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="mediaElement" style={{ display: 'flex', flexDirection: 'row' }}>
        {this.props.ids.map((id, index) => (
          <div key={index}>
            {this.state.studyIds[index] ? (
              <a
                href={this.state.config.dicomViewerUrl + '/viewer/' + this.state.studyIds[index]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/dicomIcon.png" width="100px" height="100px" style={{ paddingRight: '.5em' }} />
              </a>
            ) : (
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: '.5em',
                }}
              >
                <TailSpin
                  ariaLabel="loading-indicator"
                  color="#21264e"
                  height="20"
                  width="20"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default withTranslation()(ImagingStudy);
