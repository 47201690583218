export async function getOwnOrganization(client: any) {
  // check for own organization
  if (!client.getIdToken().fhirUser) {
    return client.getIdToken().fhirOrganization;
  } else {
    // read org for user
    return await client
      .request(`PractitionerRole?practitioner=${client.getIdToken().fhirUser}`, {
        pageLimit: 0,
        flat: true,
      })
      .then((data: any) => {
        // TODO what if there are more PractitionerRoles?
        return data[0].organization.reference;
      });
  }
  return '';
}

export async function getOwnOrganizationName(client: any) {
  if (!client.getIdToken().fhirUser) {
    return await client
      .request(`${client.getIdToken().fhirOrganization}`, {
        pageLimit: 0,
        flat: true,
      })
      .then((data: any) => {
        return data.name;
      });
  } else {
    // read org for user
    return await client
      .request(`PractitionerRole?practitioner=${client.getIdToken().fhirUser}`, {
        pageLimit: 0,
        flat: true,
        resolveReferences: ['organization'],
      })
      .then((data: any) => {
        // TODO what if there are more PractitionerRoles?
        return data[0].organization.name;
      });
  }
}

export async function getOwnPractitionerRole(client: any) {
  if (client.getIdToken().fhirUser != undefined) {
    return await client
      .request(`PractitionerRole?practitioner=${client.getIdToken().fhirUser}`, {
        pageLimit: 0,
        flat: true,
      })
      .then((data: any) => {
        return 'PractitionerRole/' + data[0].id;
      });
  }
  return '';
}

export async function getOwnDisplayname(client: any) {
  if (client.getIdToken().fhirUser != undefined) {
    return await client
      .request(`${client.getIdToken().fhirUser}`, {
        pageLimit: 0,
        flat: true,
      })
      .then((data: any) => {
        return data.name[0].given[0] + ' ' + data.name[0].family;
      });
  }
  return '';
}
